<template>
    <modal name="property-load-modal" @closed="closeModal()" :height="'auto'" class="fs-14">
        <div class="bg-primary-three shadow-two rounded-md p-10">
            <div class="flex i-border-b-2 border-secondary-four mb-5">
                <h3 class="fs-20 cursor-pointer text-primary-one i-border-b-2 border-primary-one pb-5">Load From List</h3>
            </div>
            <div class="modal-body-card">
                <div class="i-mb-30 i-pr-30 overflow-y-scroll ws-scrollbar" style="height: 200px">
                    <div v-for="(list, index) in savedItems" :key="index"
                         class="cursor-pointer flex items-center mb-2 h-10 i-pl-10 text-primary-one"
                         :class="{'rounded i-border-1 border-secondary-two': choosedItemId === list.id}"
                         @click="choosedItemId = list.id">
                        {{ list.title }}
                    </div>
                </div>
                <div class="flex">
                    <button class="h-10 rouned shadow-one text-primary-three px-8 rounded bg-secondary-one"
                            :disabled="loading"
                            @click="loadToAProperty()">
                        Load
                    </button>
                    <button class="h-10 rouned shadow-one text-secondary-one px-6 rounded i-border-1 border-secondary-one ml-5"
                            @click="closeModal()">Cancel
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "LoadProperty",
        props: ['dModal'],
        data() {
            return {
                choosedItemId: ''
            }
        },
        watch: {
            dModal: function (val) {
                if (val) {
                    this.openModal();
                } else {
                    this.closeModal();
                }
            }
        },
        computed: {
            ...mapGetters({
                loading: "GET_LOADING_STATE",
                savedItems: "manageProperty/savedProperties"
            })
        },
        methods: {
            openModal() {
                this.getAllSavedProperties();
                this.$modal.show('property-load-modal');
            },
            closeModal() {
                this.choosedItemId = '';
                this.$modal.hide('property-load-modal');
                this.$emit('toggleModalMode', false);
            },
            getAllSavedProperties() {
                this.$store.dispatch("manageProperty/getSavedProperties");
            },
            loadToAProperty() {
              const self = this;
              self.$store.state.loading = true;
              let loadObj = {
                module_id: self.$route.params.id,
                property_id: self.choosedItemId
              };
              self.$store.dispatch("manageProperty/loadAProperty", loadObj).then(response => {
                  if(response) {
                      self.$store.dispatch("manageProperty/loadProperties", self.$route.params.id);
                      self.closeModal();
                  }
                  self.$store.state.loading = false;
              });
            }
        },
    }
</script>

<style scoped>

</style>
