<template>
  <v-chart :option="chartOptions"></v-chart>
</template>

<script>
import ECharts from "vue-echarts";

export default {
  name: "ELineChart",
  components: { "v-chart": ECharts },
  props: ['width', 'height', 'data'],
  watch: {
    data: function (newData, oldData) {
      this.getOption();
    }
  },
  computed : {
    getChartData() {
      let cdata = [];
      this.data.forEach((element, index) => {
        if (element.is_visible) {
          cdata.push({
            value: element.value,
            name: element.title,
            itemStyle: {
              color: '#4BA8D9',
              borderRadius: [4, 4, 0, 0],
            }
          });
        }
      });

      return cdata;
    },
    getAxisLabel() {
      let label = [];
      this.data.forEach((element, index) => {
        if (element.is_visible) {
          label.push(element.suffix);
        }

      });

      return label;
    }
  },
  data() {
    return {
      chartOptions: null
    }
  },
  methods: {
    getOption() {
      this.chartOptions = {
        xAxis: {
          show: true,
          type: 'category',
          axisLabel: {
            show: true,
            fontSize: 14,
            color: '#3d3d3d',
            margin: 20
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#e2e2e2'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#E2E2E2'
            }
          },
          data: this.getAxisLabel,
        },
        yAxis: {
          type: 'value',
          show: true,
          axisLabel: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: "#E2E2E2",
              // type: "dashed"
            }
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: '#E2E2E2'
            }
          },
          min: 0,
          max: 10
        },
        series: [{
          data: this.getChartData,
          type: 'bar',
          // symbol: "none",
          // lineStyle: {
          //   color: '#FFFFFF',
          //   width: .2
          // }
          barCategoryGap: "60%",
        }],
      };
    }
  },
  mounted() {
    this.getOption();
  }
}
</script>

<style scoped>
  .echarts {
    width: 510px !important;
    height: 290px !important;
    margin-top: -45px;
    margin-left: -50px;
    margin-bottom: -60px;
  }
  .echarts div {
    width: 510PX !important;
    height: 290px !important
  }
  .echarts canvas {
    width: 510PX !important;
    height: 290px !important;
  }
</style>
