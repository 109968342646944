<template>
  <div class="flex justify-start fs-14">
    <div class="prop-details bg-primary-three rounded shadow-one mr-10 i-p-30 i-pt-15">
      <div class="flex justify-between mb-8 i-pb-15 i-border-b-1 border-secondary-two">
        <span class="fs-14 fw-600 text-primary-one">Details</span>
      </div>
      <div class="mb-4 shadow-two">
        <img class="rounded block w-full _my_img_size" :src="(selected.file) ? selected.file.file_path : '/images/build/demo_image_1.jpg'" alt="image">
      </div>
      <div class="prop-details-body">
        <div class="body-title mb-3 text-primary-one fw-600">
          <span class="">{{ selected.title }}</span>
        </div>
        <div class="short-desc mb-5 text-secondary-one">
          <span>{{ selected.description ? selected.description : 'No description available' }}</span>
        </div>
        <div class="value-div">
          <p class="flex justify-start mb-5"><span class="prop-item-title text-secondary-one">ID.Name:</span><span class="text-primary-one">{{ selected.title }}</span></p>
          <p class="flex justify-start mb-4"><span class="prop-item-title text-secondary-one">Creator:</span><span class="text-primary-one">24/02/2021</span></p>
          <p class="flex justify-start mb-4"><span class="prop-item-title text-secondary-one">Created:</span><span class="text-primary-one">24/02/2021</span></p>
          <p class="flex justify-start mb-4"><span class="prop-item-title text-secondary-one">Last Edited:</span><span class="text-primary-one">24/02/2021</span></p>
        </div>
        <p class="flex justify-start items-center mb-5 mt-8">
          <span class="mr-1 text-secondary-one">Highlighted P.SET:</span>
          <span class="mr-1 text-primary-one">{{ active ? active.title : '' }}</span>
        </p>
        <prop-bar-chart :data="active ? active.collections : []" ref="propDataChart"/>
      </div>

    </div>
    <div class="insight-lists p-0 bg-transparent relative">
        <div class="i-pr-30 fw-600 text-primary-one">
            <div class="flex items-center justify-between bg-primary-three shadow-one rounded i-h-50 i-pl-30 i-pr-30 mb-8">
                <h2>Insight Library</h2>
                <h2 v-if="canCreate(project.cans)" @click="createNew()" class="cursor-pointer">+ Create New</h2>
            </div>
        </div>
        <div class="overflow-y-scroll ws-scrollbar i-pr-30" style="height: 783px; width: 975px;">
            <div class="grid grid-cols-3 gap-10">
                <div class="relative lib-item-container shadow-one cursor-pointer rounded bg-primary-three i-p-15" v-for="(item, index) in insights" :key="index" :index="index" :style="{'background': item.color ? item.color : ''}">
                  <div class="mb-3">
                    <input :disabled="!canEdit(project.cans)" :style="{'text-align': item.align}" type="text" class="text-primary-one fw-700 bg-transparent w-full" v-model="item.title" @blur="update(item)">
                    <textarea :disabled="!canEdit(project.cans)" :style="{'text-align': item.align}" class="text-secondary-one fs-12 bg-transparent bld-content-area" v-model="item.description" placeholder="Short desc" @blur="update(item)">
                    </textarea>
                  </div>
                  <div class="i-pb-15 i-border-b-1 border-secondary-five mb-4">
                    <div class="insight-image-container overflow-hidden rounded bg-secondary-three relative flex items-center justify-center">
                      <span>
                        <img src="/images/icons/properties/plus_circle_outline.svg" alt="image" style="display: block" :class="{'pointer-events-none': !canEdit(project.cans)}" @click="clickOnInput(item.id)">
                      </span>
                      <input type="file" 
                             class="absolute hidden" 
                             :id="'ins-img-input-'+item.id" 
                             @change="(e)=>{item.image=e.target.files[0];update(item);changeImg(e, item.id);}" 
                             :disabled="!canEdit(project.cans)">
                      <img :id="'ins-img-'+item.id" 
                           alt="image" 
                           :src="(item.files?.length) && item.files[item.files.length-1]['file_path']" 
                           class="absolute _my_img_size top-0 left-0" 
                           :style="{display: (item?.files?.length) ? '' : 'none'}">
                    </div>
                  </div>

                  <div class="ins-tool-box flex justify-between items-center" v-if="canEdit(project.cans)">
                    <img :class="item.align == 'left' ? 'align-active' : 'align'" src="/images/icons/properties/left_align.svg" alt="" @click="item.align = 'left', update(item)">
                    <img :class="item.align == 'center' ? 'align-active' : 'align'" src="/images/icons/properties/middle_align.svg" alt="" @click="item.align = 'center', update(item)">
                    <img :class="item.align == 'right' ? 'align-active' : 'align'" src="/images/icons/properties/right_align.svg" alt="" @click="item.align = 'right', update(item)">
                    <div class="relative picker">
                      <img class="color-picker-img absolute top-0" src="/images/icons/properties/color.svg" alt="" @click="clickColor(item.id)" :class="{'pointer-events-none': !canEdit(project.cans)}">
                      <input class="absolute top-0 color-picker" 
                             type="color" 
                             :id="'color-picker-'+item.id" 
                             v-model="item.color" 
                             @change="update(item)" 
                             :disabled="!canEdit(project.cans)">
                    </div>

                    <img src="/images/icons/properties/trash_empty.svg" alt="" v-if="canDelete(project.cans)" @click="deleteItem(item)">
                  </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js'
  const { canCreate, canEdit, canDelete } = usePermission()
</script>

<script>
import PropBarChart from "@/components/properties/EBarChart"
import { mapGetters } from 'vuex';
export default {
  name: "Insights",
  props: ["selected", "properties"],
  components: { "prop-bar-chart": PropBarChart },
  data() {
    return {
      active : this.properties[0],
      colorCodes: ['#3B7664', '#3B4876', '#763B6D'],
      associatedList: [
        { id: 2, title: 'C2001.1.1.1 leadership capability', img: 'area_1.png', tag: 'accelerator' },
      ],
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur et magna eu eros rutrum facilisis.',
    }
  },
  computed: {
    ...mapGetters({
      activeProject: 'workspace/activeProject',
      insights: 'insight/data',
      project: 'project/project'
    })
  },
  methods: {
    createNew(){
      let data = {}
      data.scenario_collection_id = this.selected.id
      data.title = 'Headline'
      this.$store.dispatch('insight/storeData', data)
      .then(
        this.$store.dispatch('insight/fetchIndexData', this.selected.id)
      )
    },
    update(item) {
      setTimeout(() => {
        this.$store.dispatch('insight/updateData', item)
        .then(
          this.$store.dispatch('insight/fetchIndexData', this.selected.id)
        )
      }, 100);
    },
    clickColor(id) {
      document.getElementById('color-picker-'+id).click();
    },
    deleteItem(item) {
      this.$store.dispatch('insight/deleteItem', item)
      .then(
        this.$store.dispatch('insight/fetchIndexData', this.selected.id)
      )
    },
    clickOnInput(id) {
      let input = document.getElementById("ins-img-input-"+id);
      input.click();
    },
    changeImg(e, id) {
      let display = document.getElementById("ins-img-"+id);
      display.style.display = 'block'
      display.src = window.URL.createObjectURL(e.target.files[0]);
      display.onload = function() {
        window.URL.revokeObjectURL(display.src);
      };
    },
  }
}
</script>

<style scoped>
.prop-details, .insight-lists {
  height: 865px;
}
.prop-details {
  width: 470px;
}
.value-div .prop-item-title {
  width: 130px;
}
.lib-item-container {
    width: 290px;
}
textarea {
  background: transparent;
  height: 75px;
  width: 260px;
}
.align {
  opacity: .6;
}
.align-active {
  opacity: 1
}
.color-picker {
  border-radius: 50%;
  height: 1px;
  width: 1px;
  border: none;
  outline: none;
  background: transparent;
  -webkit-appearance: none;
}
.color-picker-img {
  width: 18px;
  height: 18px;
}
.picker {
  height: 18px;
  width: 18px;
}
.ins-tool-box img{
  cursor: pointer;
}
.insight-image-container {
  height: 105px;
}
</style>
