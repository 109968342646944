<template>
  <div class="p-0 bg-transparent relative fs-14">
    <div class="i-pr-30 fw-600 text-primary-one property-command-area">
      <div class="flex items-center justify-between bg-primary-three shadow-one rounded i-h-50 i-pl-30 i-pr-30 mb-8">
        <h2>P. Set: {{ rootItem ? rootItem.title : '' }}</h2>
        <div class="flex justify-between items-center" v-if="canEdit(project.cans) || canCreate(project.cans)">
          <h2 class="cursor-pointer mr-10" :style="{'visibility' : rootItem.id ? 'visible' : 'hidden'}" @click="modals.newParameter = true">+ Add New Parameter</h2>
          <h2 class="cursor-pointer" :style="{'visibility' : rootItem.id ? 'visible' : 'hidden'}" @click="modals.saveProperty = true">Save Set</h2>
        </div>
      </div>
    </div>

    <div :class="{'pointer-events-none': !canEdit(project.cans) && !canCreate(project.cans)}" class="overflow-y-scroll ws-scrollbar i-pr-30 text-secondary-one fs-14" style="height: 783px; width: 975px;">
      <div v-if="rootItem.id">
        <div class="bg-primary-three shadow-one rounded mb-8 i-pl-30 i-pr-30" v-for="(set, index) in rootItem.property_collections" :key="index">
          <div class="flex justify-between items-center i-h-50 mb-6" :class="selectedChild.id === set.id ? 'i-border-b-1 border-secondary-two' : ''">
            <div class="flex">
              <div class="flex items-center">
                <h2 class="cursor-pointer property-title-width ellipse" @click="$emit('toggleChildSelection', set)">{{ set.title }}</h2>
                <color-picker
                    content="Click to choose color"
                    v-tippy="{ placement : 'top',  arrow: true }"
                    :disabled="editableId !== set.id"
                    :class="{'pointer-events-none': editableId !== set.id}"
                    v-model="set.bg_color"
                    :position="{left: '40px', top: '0'}" class="prop-picker-section i-border-1 border-secondary-two ml-4" />
              </div>
            </div>
            <div class="flex items-center" v-if="canEdit(project.cans) || canCreate(project.cans)">
              <h2 class="cursor-pointer" v-if="editableId === set.id" @click="updateSet(set)">Update</h2>
<!--              <img src="/images/icons/properties/settings_future.svg" alt="image" class="cursor-pointer" @click="toggleEditMode(set.id)" :class="{'opacity-6': editableId === set.id, 'pointer-events-none': selectedChild.id !== set.id}" v-show="selectedChild.id === set.id">-->
            </div>
          </div>
          <div class="flex justify-between" v-if="selectedChild.id === set.id">
            <div class="prop-parameter">
              <div class="flex items-center mb-4">
                <h2 class="">Type:</h2>
                <select disabled class="bg-transparent cursor-pointer no-dropdown pr-select-dropdown"
                        v-model="set.type">
                  <option value="1">Solid</option>
                  <option value="2">Calculative</option>
                </select>
              </div>
              <div class="flex items-center justify-between mb-4">
                <div class="flex">
                  <h2 class="">Option:</h2>
                  <select :disabled="editableId !== set.id" class="bg-transparent cursor-pointer pr-select-dropdown"
                          @change="setWithOption(set)"
                          v-model="set.option">
                    <option value="1">Slider Parameter</option>
                    <option value="2">Field Parameter</option>
                  </select>
                </div>
                <div class="flex items-center ml-3" v-if="set.option == 2">
                  <input type="checkbox" v-model="set.same_value_apply_to_all" /><label class="ml-1">Common value </label>
                </div>
              </div>
              <div class="flex items-center mb-4">
                <h2 class="">Sample value:</h2>
                <span>0.00</span>
              </div>
              <div class="flex items-center mb-4">
                <h2 class="">Format:</h2>
                <select :disabled="editableId !== set.id || set.option == 2" class="bg-transparent cursor-pointer pr-select-dropdown" v-model="set.format">
                  <option value="1">Number Value</option>
                  <option value="2">Percentage Value</option>
                </select>
              </div>
              <div class="flex items-center mb-4">
                <h2 class="">Prefix:</h2>
                <input :disabled="editableId !== set.id" class="bg-transparent" placeholder="None" v-model="set.prefix" />
              </div>
              <div class="flex items-center mb-4">
                <h2 class="">Suffix:</h2>
                <input :disabled="editableId !== set.id" class="bg-transparent" placeholder="FS" v-model="set.suffix" />
              </div>
              <div class="flex items-center mb-4">
                <h2 class="">Definition Set:</h2>
                <select :disabled="editableId !== set.id" class="bg-transparent cursor-pointer pr-select-dropdown" v-model="set.definition_id" placeholder="Select definition">
                  <option value="null" disabled>Select Definition</option>
                  <option v-for="(definition, dIndex) in definitionSets" :key="dIndex" :value="definition.id">{{ definition.title }}</option>
                </select>
              </div>
              <div class="flex items-center mb-4">
                <h2 class="">Explanation:</h2>
                <p class="focus-outline-none" :contenteditable="editableId === set.id" style="min-width: 150px;">{{ set.explanation ? set.explanation : 'Type here' }}</p>
              </div>
            </div>
            <div class="prop-m-child-right i-mb-30" v-if="set.type === 2">
              <div class="prop-child-calculator-box flex flex-wrap p-4 relative i-border-1 border-secondary-two rounded mb-5">
                <div class="px-2 h-8 flex items-center i-mb-10 text-primary-one fs-20" v-for="(calItem, calIndex) in set.formula" :key="calIndex" :class="{'inputtype-calc-item': calItem.is_number}">
                  <h2 v-if="calItem.id > 0 && !calItem.is_number && !calItem.is_operator && !calItem.is_result">{{ getPropertyPrefix(calItem) }}</h2>
                  <h2 v-if="calItem.is_operator">{{ calItem.value }}</h2>
                  <h2 v-if="calItem.is_result">{{ calItem.value }}</h2>
                  <input type="number" class="bg-transparent numinputcalc w-6" v-if="calItem.is_number" v-model="calItem.value" placeholder="1" />
                </div>
                <div :class="{'pointer-events-none': editableId !== set.id}" class="absolute clear-calc-box fw-600 text-danger-one cursor-pointer" @click="clearTheFormula(set)">Clear all</div>
              </div>
              <div class="flex justify-between fw-600 mb-4">
                <div class="property-selector-root-box flex items-center justify-between h-10 px-4 i-border-1 border-secondary-two rounded-sm i-mr-15">
                  <div class="flex items-center text-secondary-one">
                    <h2 class="mr-2">Property:</h2>
                    <select :disabled="editableId !== set.id" v-model="prop_calc" class="cursor-pointer uppercase pr-select-dropdown bg-transparent">
                      <option v-for="(propertyChild, pCIndex) in getSolidProperty()" :key="pCIndex" :value="propertyChild.id">{{ propertyChild.prefix }}</option>
                    </select>
                  </div>
                  <div class="flex items-center cursor-pointer" :class="{'pointer-events-none': editableId !== set.id || set.finalised}" @click="addProperty(set)">
                    <h2 class="mr-2">Input</h2>
                    <img src="/images/icons/properties/right_arrow.svg" alt="image" class="cursor-pointer">
                  </div>
                </div>
                <div class="h-10 px-4 i-border-1 border-secondary-two rounded-sm flex items-center justify-center cursor-pointer" :class="{'pointer-events-none': editableId !== set.id || set.finalised}" @click="addNumber(set)">Num</div>
              </div>
              <div class="flex justify-between" :class="{'pointer-events-none': editableId !== set.id || set.finalised}">
                <div class="cursor-pointer flex items-center justify-center h-10 w-10 i-border-1 border-secondary-two rounded-sm" @click="addOperator(set, '+')">+</div>
                <div class="cursor-pointer flex items-center justify-center h-10 w-10 i-border-1 border-secondary-two rounded-sm" @click="addOperator(set, '-')">-</div>
                <div class="cursor-pointer flex items-center justify-center h-10 w-10 i-border-1 border-secondary-two rounded-sm" @click="addOperator(set, '*')">*</div>
                <div class="cursor-pointer flex items-center justify-center h-10 w-10 i-border-1 border-secondary-two rounded-sm" @click="addOperator(set, '/')">/</div>
                <div class="cursor-pointer flex items-center justify-center h-10 w-10 i-border-1 border-secondary-two rounded-sm" @click="addOperator(set, '(')">(</div>
                <div class="cursor-pointer flex items-center justify-center h-10 w-10 i-border-1 border-secondary-two rounded-sm" @click="addOperator(set, ')')">)</div>
                <div class="cursor-pointer flex items-center justify-center h-10 w-16 i-border-1 border-secondary-two rounded-sm" @click="finalizeFormula(set)">Enter</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <new-parameter :dModal="modals.newParameter" :rootItem="rootItem" @toggleModalMode='toggleNewParameterModalMode' @loadProperties="loadProperties" />
    <save-property :dModal="modals.saveProperty" :rootItem="rootItem" @toggleModalMode="toggleSaveModalMode" />
  </div>
</template>

<script setup>
import usePermission from '@/composable/usePermission.js'
const { canDelete, canCreate, canEdit } = usePermission()
</script>
<script>
import NewParameter from "@/components/properties/NewParameter";
import SaveProperty from "@/components/properties/SaveProperty";
import {mapGetters} from 'vuex';
import {cloneDeep} from "lodash";
export default {
  name: "ManageSet",
  props: ['rootItem', 'selectedChild', 'selected'],
  components: {
    NewParameter,
    SaveProperty,
  },
  data() {
    return {
      editableId: '',
      modals: {
        newParameter: false,
        saveProperty: false,
      },
      prop_calc: 0
    }
  },
  computed: {
    ...mapGetters({
      activeModuleCollection: "build/activeModuleCollection",
      definitionSets: 'definition/definitionSets',
      project: "project/project"
    }),
  },
  watch: {
    selectedChild: function(val) {
      const self = this;
      setTimeout(() => {
        let items = self.getSolidProperty();
        if(items && items.length > 0) {
          self.prop_calc = items[0].id;
        }
      }, 500);
    }
  },
  methods: {
    setWithOption(set) {
      if(set.option == 2) {
        set.format = 1;
      }
    },
    toggleEditMode(itemId) {
      // if(this.editableId === itemId) {
      //   this.editableId = '';
      // } else {
        this.editableId = itemId;
      // }
    },
    toggleNewParameterModalMode(value) {
      this.modals.newParameter = value;
    },
    toggleSaveModalMode(value) {
      this.modals.saveProperty = value;
    },
    getSolidProperty() {
      return this.rootItem.property_collections.filter(item => item.type === 1);
      // if(this.selectedChild && parseInt(this.selectedChild.type) === 2) {
      //   //
      // } else {
      //   return this.rootItem.property_collections.filter(item => item.type === 1);
      // }
    },
    getSolidPropertySerial(id) {
      let itemIndex = this.getSolidProperty().findIndex(item => item.id === id);
      if(itemIndex >= 0) {
        return itemIndex + 1;
      }
      return 0;
    },
    getPropertyPrefix(calItem) {
      let data = this.rootItem.property_collections.find(item => parseInt(item.id) === parseInt(calItem.id));
      if(data) {
        return data.prefix;
      }
      return '';
    },
    addOperator(setItem, operator) {
      let calcObject = {
        id: false,
        value: operator,
        is_number: false,
        is_result: false,
        is_operator: true
      };
      setItem.formula.push(calcObject);
      setItem.formula_updated = true;
    },
    addNumber(setItem) {
      let calcObject = {
        id: false,
        value: 1,
        is_number: true,
        is_result: false,
        is_operator: false
      };
      let lastPushedItem = setItem.formula[setItem.formula.length - 1];
      if(setItem.formula.length <= 0 || lastPushedItem.is_operator) {
        setItem.formula.push(calcObject);
      } else {
        this.addOperator(setItem, '*');
        setItem.formula.push(calcObject);
      }
      setItem.formula_updated = true;
    },
    addProperty(setItem) {
      let calcObject = {};
      if(this.prop_calc) {
        calcObject = {
          id: this.prop_calc,
          value: '',
          is_number: false,
          is_result: false,
          is_operator: false,
          solid_property_serial: this.getSolidPropertySerial(this.prop_calc)
        };
      }
      if(setItem.formula.length > 0 && calcObject) {
        let lastPushedItem = setItem.formula[setItem.formula.length - 1];
        if(setItem.formula.length <= 0 || !lastPushedItem.is_operator) {
          this.addOperator(setItem, '*');
        }
        setItem.formula.push(calcObject);
      } else if(calcObject) {
        setItem.formula.push(calcObject);
      }
      setItem.formula_updated = true;
    },
    finalizeFormula(setItem) {
      let calcObject = {
        id: false,
        value: '=',
        is_number: false,
        is_result: true,
        is_operator: false
      };
      setItem.formula.push(calcObject);

      calcObject = {
        id: false,
        value: 'Result',
        is_number: false,
        is_result: true,
        is_operator: false,
      };
      setItem.formula.push(calcObject);
      setItem.finalised = true;
      setItem.formula_updated = true;
    },
    clearTheFormula(setItem) {
      setItem.formula = [];
      setItem.finalised = false;
      setItem.formula_updated = true;
    },
    updateSet(setItem) {
      let currentSet = setItem;
      const self = this;
      let data = cloneDeep(setItem);
      if(data.formula && data.formula.length > 0) {
        data.dependent_properties = JSON.stringify(data.formula.filter(item => item.id && item.id >= 0).map(item => item.id));
      }
      data.formula = JSON.stringify(data.formula);
      data.module_id = self.$route.params.id;
      delete data.is_visible;
      self.$Progress.start();
      self.$store.dispatch("manageProperty/updatePropertyCollectionItem", data).then(response => {
        self.$Progress.finish();
        this.selectedChild = currentSet;
        self.$store.dispatch("build/getProperties", self.activeModuleCollection.id);
      });
    },
    loadProperties() {
      this.$emit("loadProperties");
    }
  },
}
</script>

<style scoped>
.property-command-area {
  width: 975px;
}
.numinputcalc::placeholder {
  color: #787878;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
.prop-parameter h2 {
  width: 140px;
}
/* test old  */
.prop-m-child-right {
    width: 410px;
}
.prop-child-calculator-box {
    height: 153px;
}

.clear-calc-box {
    bottom: 10px;
    right: 10px;
}

/* .property-selector-root-box,
.calc-number-assign-btn {
    box-shadow: 0px 8px 30px -15px rgba(0, 0, 0, 0.4);
    border-radius: 2px;
    height: 40px;
} */

.property-selector-root-box {
    width: 340px;
}

.prop-selector-child-selectbox,
.prop-selector-child-selectbox:focus {
    border: none;
    box-shadow: none;
}
.no-dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}
.property-title-width {
  width: 250px;
}
input[type=checkbox] {
  visibility: visible;
}
</style>
