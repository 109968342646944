<template>
  <div class="flex justify-start fs-14">
    <div class="prop-details bg-primary-three rounded shadow-one mr-10 i-p-30 i-pt-15">
      <div class="flex justify-between mb-8 i-pb-15 i-border-b-1 border-secondary-two">
        <span class="fs-14 fw-600 text-primary-one">Details</span>
      </div>
      <div class="mb-4 shadow-two relative">
        <img v-if="previewURL" class="rounded block w-full _my_img_size" :src="previewURL" alt="image">
        <img v-else class="rounded block w-full _my_img_size" :src="(selected.file) ? selected.file.file_path : '/images/build/demo_image_1.jpg'" alt="image">
        <label v-if="canEdit(project.cans)"  class="absolute right-1 bottom-1 property-image-icon cursor-pointer">
          <input @input="(e)=>{onChange(e), updateImage(
                  {file, id:selected.id},
                  ()=>{
                      selected.file = {file_path: previewURL}
                      previewURL = ''
                  }
              )}"
              hidden type="file" accept="image/x-png,image/jpeg" />

          <svg class="pointer-events-none" xmlns="http://www.w3.org/2000/svg" width="18" height="13" viewBox="0 0 18 13" fill="none">
              <path d="M0 12.3477H12V10.3477H0V12.3477ZM0 7.34766H18V5.34766H0V7.34766ZM0 2.34766H12V0.347656H0V2.34766Z" fill="#E2E2E2"/>
          </svg>
        </label>
      </div>
      <div class="prop-details-body">
        <div class="body-title mb-3 text-primary-one fw-600">
          <span class="">{{ selected.title }}</span>
        </div>
        <div class="short-desc mb-5 break-words text-secondary-one">
          <span>{{ selected.description ? selected.description : 'No description available' }}</span>
        </div>
        <div class="value-div">
          <p class="flex justify-start mb-5 text-secondary-one"><span class="prop-item-title">ID Name:</span><span class="text-primary-one">{{ selected.title }}</span></p>
          <p class="flex justify-start mb-4 text-secondary-one">
            <span class="prop-item-title">Creator:</span><span class="text-primary-one">
              {{ selected.created_by ? selected.created_by.first_name + ' ' + (selected.created_by.last_name ? selected.created_by.last_name : '') : '' }}
            </span>
          </p>
          <p class="flex justify-start mb-4 text-secondary-one"><span class="prop-item-title">Created:</span><span class="text-primary-one">{{ selected.created_at ? selected.created_at.split('T')[0] : '' }}</span></p>
          <p class="flex justify-start mb-4 text-secondary-one"><span class="prop-item-title">Last Edited:</span><span class="text-primary-one">{{ selected.updated_at ? selected.updated_at.split('T')[0] : '' }}</span></p>
        </div>
        <p class="flex justify-start items-center mb-5 mt-8">
          <span class="mr-1 text-secondary-one">Highlighted P.SET:</span>
          <span class="mr-1 text-primary-one">{{ active ? active.title : '' }}</span>
        </p>
        <prop-bar-chart :data="active ? active.collections : []" ref="propDataChart"/>
      </div>

    </div>
    <div class="prop-lists p-0 bg-transparent relative">
      <div v-if="canCreate(project.cans)" class="i-pr-30">
        <div class="flex items-center cursor-pointer bg-primary-three shadow-one rounded i-h-50 i-pl-30 mb-8">
          <span class="fw-600 text-primary-one" @click="$parent.selectPropAreaType(3)">+ Create Property Set</span>
        </div>
      </div>
      <div class="overflow-y-scroll ws-scrollbar i-pr-30" style="height: 783px; width: 975px;">
        <div class="prop-set-div bg-primary-three shadow-one rounded i-pl-30 i-pr-30 mb-8" v-for="(dataset, index) in getProperties" :key="index">
          <div class=" flex justify-between items-center cursor-pointer i-h-50 border-secondary-two text-primary-one fw-600"
              @click="statusToggle(index)"
              :class="{'i-border-b-1 mb-4': dataset.collections.length > 0 && active && active.id == dataset.id}">
            <span>P.Set: {{ dataset.title }}</span><img v-show="active && active.id == dataset.id" @click="$parent.selectPropAreaType(3)" src="/images/icons/build/settings.svg" alt="image">
          </div>
          <div v-show="active && active.id == dataset.id" class="pb-1">
            <div class="flex justify-start items-center mb-6" v-for="(prop, index) in dataset.collections.filter(el => el.is_visible)" :key="index">
              <span class="prop-items text-secondary-one">{{ prop.title }}</span>
              <div class="track-box relative" v-if="!prop.option || prop.option == 1">
                <input class="range absolute left-0 top-0 cursor-pointer w-full bg-secondary-four" min="0" max="10" step=".1" type="range"
                    :class="{'prop-disabled': parseInt(prop.type) === 2}" 
                    :disabled="parseInt(prop.type) === 2 || !canEdit(project.cans)"
                    v-model="prop.value"
                    v-if="activeModuleCollection.child.length == 0"
                    @change="getUpdateData(prop)"
                />
                <div class="value-track absolute left-0 top-0 w-full bg-secondary-four" v-else></div>
                <div class="progress-track absolute left-0 top-0 shadow-two" :style="{'width': (prop.value * 10) +'%'}"></div>
              </div>
              <div class="track-box relative" v-if="prop.option == 2">
                <input type="number" class="bg-transparent text-primary-one fs-14 rounded-sm i-border-1 border-secondary-five px-2"
                       v-if="activeModuleCollection && activeModuleCollection.child"
                       :disabled="activeModuleCollection.child.length > 0 || !canEdit(project.cans)"
                       v-model="prop.value" @keyup="getUpdateData(prop)" />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
  import axios from 'axios';
  import { useFile } from '@/composable/useFile.js'
  const { onChange, previewURL, file } = useFile()

  import usePermission from '@/composable/usePermission.js'
  const { canDelete, canCreate, canEdit } = usePermission()

  const updateImage = async ({file, id}, callback) => {
    let formData = new FormData()
    formData.append('id', id)
    formData.append('image', file)
    await axios.post('projects/scenarios/collections/image', formData)
    callback()
  }
</script>

<script>
import PropBarChart from "@/components/properties/EBarChart"
import { mapGetters } from 'vuex';
export default {
  name: "Properties",
  props: ["item", "selected", "properties"],
  components: { "prop-bar-chart": PropBarChart },
  data() {
    return {
      active : this.properties[0]
    }
  },
  computed: {
    ...mapGetters({
      activeProject: 'workspace/activeProject',
      activeModuleCollection: "build/activeModuleCollection",
      project: "project/project"
    }),
    checkCustomizable() {
      if (this.item) {
        return this.item.child.length == 0 ? true : false
      }
      return false
    },
    getProperties() {
      if(this.properties.length > 0) {
        return this.properties.filter(el => el.is_visible)
      }
      return [];
    }
  },
  watch: {
    properties: function (newProp, oldProp) {
      this.active = this.properties[0]
      this.statusToggle(0)
    }
  },
  methods: {
    statusToggle(index) {
      this.active = this.properties[index]
    },
    getUpdateData(obj) {
      obj.mc_id = this.selected.id;
      this.$store.dispatch("build/updatePropertyItem", obj);
      setTimeout(() => {
        this.$refs['propDataChart'].getOption()
      }, 100);
    }
  }
}
</script>

<style scoped>
.prop-details, .prop-lists {
  height: 865px;
}
.prop-details {
  width: 470px;
}
.value-div .prop-item-title {
  width: 130px;
}
.prop-items {
  width: 210px;
  padding-right: 15px;
}
.value-title {
  width: 160PX;
}
.track-box {
  width: 675px;
}
.value-track {
  height: 2px;
}
.progress-track {
  height: 2px;
  background: #00A7FF;
}
.range {
  height: 2px;
  outline: none;
  -webkit-appearance: none;
}
.prop-disabled[type='range']::-webkit-slider-thumb {
  cursor: not-allowed !important;
  display: none;
}
input[type='range']::-webkit-slider-thumb {
  width: 30px;
  -webkit-appearance: none;
  height: 15px !important;
  cursor: pointer;
  background: #00A7FE;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
</style>
