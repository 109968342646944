<template>
    <modal name="parameter-save-modal" @closed="closeModal()" :height="'auto'" class="fs-14">
        <div class="bg-primary-three shadow-two rounded-md p-10">
            <div class="flex i-border-b-2 border-secondary-four i-mb-30">
                <h3 class="fs-20 cursor-pointer text-primary-one i-border-b-2 border-primary-one pb-5">New parameter</h3>
            </div>
            <div class="modal-body-card">
                <div class="i-mb-30">
                    <h4 class="text-secondary-one mb-2">Title</h4>
                    <div class="flex justify-between items-center relative">
                        <input type="text"
                                class="bg-transparent px-4 i-border-2 border-secondary-five rounded h-10 w-full text-primary-one"
                                :placeholder="placeholder.title" @click="placeholder.title = ''"
                                v-click-outside="setTitlePlaceholder"
                                v-model="parameterObj.title">
                    </div>
                </div>
                <div class="i-mb-30">
                    <h4 class="text-secondary-one mb-2">Explanation (Optional)</h4>
                    <div class="flex justify-between items-center relative">
                        <textarea
                            class="bg-transparent px-4 i-border-2 border-secondary-five rounded h-10 w-full bld-content-area text-primary-one"
                            :placeholder="placeholder.explanation" @click="placeholder.explanation = ''"
                            v-click-outside="setExplanationPlaceholder" v-model="parameterObj.explanation" rows="4"
                            style="height: auto"/>
                    </div>
                </div>
                <div class="flex i-mb-30">
                    <div class="flex items-center">
                        <img src="/images/icons/workspace/checkbox.svg" alt="checkbox" class="cursor-pointer" v-if="parameterObj.type !== 1" @click="parameterObj.type = 1">
                        <img src="/images/icons/workspace/checkbox_checked.svg" alt="checkbox" class="cursor-pointer" v-if="parameterObj.type === 1" @click="parameterObj.type = 1">
                        <h4 class="ml-2 cursor-pointer text-primary-one" @click="parameterObj.type = 1">Solid Parameter</h4>
                    </div>
                    <div class="flex items-center ml-4">
                        <img src="/images/icons/workspace/checkbox.svg" alt="checkbox" class="cursor-pointer" v-if="parameterObj.type !== 2" @click="parameterObj.type = 2">
                        <img src="/images/icons/workspace/checkbox_checked.svg" alt="checkbox" class="cursor-pointer" v-if="parameterObj.type === 2" @click="parameterObj.type = 2">
                        <h4 class="ml-2 cursor-pointer text-primary-one" @click="parameterObj.type = 2">Calculative Parameter</h4>
                    </div>
                </div>
                <div class="flex">
                    <button class="h-10 rouned shadow-one text-primary-three px-8 rounded bg-secondary-one " @click="addPropertyCollection()" :disabled="loading">
                        Save
                    </button>
                    <button class="h-10 rouned shadow-one text-secondary-one px-6 rounded i-border-1 border-secondary-one ml-5" @click="closeModal()">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
    import {mapGetters} from 'vuex';
    export default {
        name: "NewParameter",
        props: ['dModal', 'rootItem'],
        data() {
            return {
                placeholder: {
                    title: 'Type here',
                    explanation: 'Type here'
                },
                parameterObj: {
                    title: '',
                    explanation: '',
                    type: 1
                }
            }
        },
        watch: {
            dModal: function (val) {
                if (val) {
                    this.openModal();
                } else {
                    this.closeModal();
                }
            }
        },
        computed: {
          ...mapGetters({
              loading: "GET_LOADING_STATE",
              activeModuleCollection: "build/activeModuleCollection",
          })
        },
        methods: {
            setTitlePlaceholder() {
                this.placeholder.title = 'Type here';
            },
            setExplanationPlaceholder() {
                this.placeholder.explanation = 'Type here';
            },
            openModal() {
                this.$modal.show('parameter-save-modal');
            },
            closeModal() {
                this.placeholder = {
                    title: 'Type here',
                    explanation: 'Type here'
                };
                this.parameterObj = {
                    title: '',
                    explanation: '',
                    type: 1
                };
                this.$modal.hide('parameter-save-modal');
                this.$emit('toggleModalMode', false);
            },
            addPropertyCollection() {
                const self = this;
                self.$store.state.loading = true;
                let formula = [
                    {
                        id: false,
                        value: '0',
                        is_number: true,
                        is_result: false,
                        is_operator: false
                    }
                ];
                let data = self.parameterObj;
                data.property_id = self.rootItem.id;
                data.formula = JSON.stringify(formula);
                data.module_id = self.$route.params.id;
                self.$store.dispatch("manageProperty/addPropertyCollection", data)
                .then(response => {
                    if(response) {
                        self.$emit("loadProperties");
                        self.$store.dispatch("build/getProperties", self.activeModuleCollection.id);
                        self.closeModal();
                    } else {
                        alert('Something went wrong.');
                    }
                    self.$store.state.loading = false;
                });
            }
        },
    }
</script>

<style scoped>

</style>
