<template>
    <modal name="property-save-set-modal" @closed="closeModal()" :height="'auto'" :classes="'bg-primary-three'">
        <div class="p-10">
            <div class="flex i-border-b-1 border-secondary-four">
                <h3 class="font-poppins text-primary-one opacity-50 fs-20 cursor-pointer" :class="{'ws-modal-item-selector i-border-b-1 border-primary-one opacity-100': is_creating}"
                    @click="is_creating = true, showList = false">
                    Save as new</h3>
                <h3 class="font-poppins text-primary-one opacity-50 fs-20 ws-modal-mr cursor-pointer" :class="{'ws-modal-item-selector i-border-b-1 border-primary-one opacity-100': !is_creating}"
                    @click="is_creating = false, showList = true">
                    Update</h3>
            </div>
            <div class="i-mt-30">
                <div class="" :class="{'d-none': showList}">
                    <h4 class="font-poppins text-secondary-one fs-14">Title</h4>
                    <div class="flex justify-between items-center mt-2 relative">
                        <div class="modal-collaborator flex-3 pr-3 relative">
                            <input type="text"
                                   class="bg-transparent font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 i-border-1 border-solid border-primary-one rounded"
                                   :placeholder="placeholder.title" @click="placeholder.title = ''"
                                   v-click-outside="setTitlePlaceholder"
                                   v-model="propObj.title">
                        </div>
                        <h2 class="font-poppins fs-14 absolute open-list-btn cursor-pointer text-primary-one" @click="showList = true" v-if="!is_creating">Open List</h2>
                    </div>
                </div>
                <div class="i-mb-30 i-pr-30 overflow-y-scroll ws-scrollbar" v-if="showList && !is_creating" style="height: 200px">
                    <div v-for="(list, index) in savedItems" :key="index"
                         class="cursor-pointer flex items-center mb-2 h-10 i-pl-10 ws-update-item text-primary-one"
                         :class="{'rounded i-border-1 border-secondary-two': choosedItemId === list.id}"
                         @click="choosedItemId = list.id, propObj.title = list.title, showList = false">
                        {{ list.title }}
                    </div>
                </div>
                <div class="flex i-mt-30">
                    <button v-if="is_creating" class="fs-14 fw-600 font-poppins i-pl-35 i-pr-35 rounded bg-secondary-one text-primary-three h-10 w-40 shadow-one"
                            :disabled="loading || !propObj.title" @click="saveTheSet()">
                        Save
                    </button>
                    <button v-if="!is_creating" class="fs-14 fw-600 font-poppins i-pl-35 i-pr-35 rounded bg-secondary-one text-primary-three h-10 w-40 shadow-one"
                            :disabled="loading || !propObj.title || showList || !choosedItemId" @click="updateTheSet()">
                        Update
                    </button>
                    <button class="focus:outline-none fs-14 fw-600 font-poppins i-pl-35 i-pr-35 i-border-1 border-secondary-one ml-5 rounded text-primary-one h-10 w-40"
                            @click="closeModal()">Cancel
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "SaveProperty",
        props: ['dModal', 'rootItem'],
        data() {
            return {
                is_creating: true,
                lists: [
                    {id: 1, title: 'Property One'},
                    {id: 2, title: 'Property two'},
                    {id: 3, title: 'Property three'},
                    {id: 4, title: 'Property four'},
                ],
                placeholder: {
                    title: 'Type here',
                    description: 'Type here'
                },
                propObj: {
                    title: '',
                    description: ''
                },
                showList: false,
                choosedItemId: ''
            }
        },
        watch: {
            dModal: function (val) {
                if (val) {
                    this.openModal();
                } else {
                    this.closeModal();
                }
            },
            rootItem: function(val) {
                if(val) {
                    this.propObj.title = val.title;
                }
            }
        },
        computed: {
            ...mapGetters({
                loading: "GET_LOADING_STATE",
                savedItems: "manageProperty/savedProperties"
            })
        },
        methods: {
            setTitlePlaceholder() {
                this.placeholder.title = 'Type here';
            },
            setDescriptionPlaceholder() {
                this.placeholder.description = 'Type here';
            },
            openModal() {
                this.getAllSavedProperties();
                this.$modal.show('property-save-set-modal');
            },
            closeModal() {
                this.$modal.hide('property-save-set-modal');
                this.showList = false;
                this.choosedItemId = '';
                this.propObj = {
                  title: ''
                };
                this.$emit('toggleModalMode', false);
            },
            getAllSavedProperties() {
                this.$store.dispatch("manageProperty/getSavedProperties");
            },
            saveTheSet() {
                const self = this;
                self.$store.state.loading = true;
                let data = {
                  title: self.propObj.title,
                  is_default: self.rootItem.is_default,
                  is_visible: self.rootItem.is_visible
                };
                let properties = self.rootItem.property_collections.filter(item => item.type === 1);
                self.rootItem.property_collections.forEach(item => {
                    if(item.type === 2) {
                        properties.push(item);
                    }
                });
                data.properties = properties;
                self.$store.dispatch("manageProperty/saveAsNewProperty", data).then(response => {
                    if(response) {
                        self.closeModal();
                    }
                    self.$store.state.loading = false;
                });
            },
            updateTheSet() {
                const self = this;
                self.$store.state.loading = true;
                let data = {
                    updatable_id: self.choosedItemId,
                    title: self.propObj.title,
                    is_default: self.rootItem.is_default,
                    is_visible: self.rootItem.is_visible
                };
                let properties = self.rootItem.property_collections.filter(item => item.type === 1);
                self.rootItem.property_collections.forEach(item => {
                    if(item.type === 2) {
                        properties.push(item);
                    }
                });
                data.properties = properties;
                self.$store.dispatch("manageProperty/updateTheSet", data).then(response => {
                    if(response) {
                        self.closeModal();
                    }
                    self.$store.state.loading = false;
                });
            }
        },
    }
</script>

<style scoped>
.open-list-btn {
    top: -28px;
}
</style>
