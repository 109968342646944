<template>
    <modal name="property-save-modal" @closed="closeModal()" :height="'auto'" class="fs-14">
        <div class="bg-primary-three shadow-two rounded-md p-10">
            <div class="flex i-border-b-2 border-secondary-four i-mb-30">
                <h3 class="fs-20 cursor-pointer text-primary-one i-border-b-2 border-primary-one pb-5">New property</h3>
            </div>
            <div class="modal-body-card">
                <div class="i-mb-30">
                    <h4 class="text-secondary-one mb-2">Title</h4>
                    <div class="flex justify-between items-center relative">
                        <input type="text"
                                class="bg-transparent px-4 i-border-2 border-secondary-five rounded h-10 w-full text-primary-one"
                                :placeholder="placeholder.title" @click="placeholder.title = ''"
                                v-click-outside="setTitlePlaceholder"
                                v-model="propObj.title">
                    </div>
                </div>
                <div class="flex">
                    <button class="h-10 rouned shadow-one text-primary-three px-8 rounded bg-secondary-one " :disabled="loading" @click="saveProperty()">Save</button>
                    <button class="h-10 rouned shadow-one text-secondary-one px-6 rounded i-border-1 border-secondary-one ml-5" :disabled="loading" @click="closeModal()">Cancel</button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
    import {mapGetters} from 'vuex';
    export default {
        name: "NewProperty",
        props: ['dModal'],
        data() {
            return {
                placeholder: {
                    title: 'Type here'
                },
                propObj: {
                    title: ''
                }
            }
        },
        watch: {
            dModal: function (val) {
                if (val) {
                    this.openModal();
                } else {
                    this.closeModal();
                }
            }
        },
        computed: {
          ...mapGetters({
              loading: "GET_LOADING_STATE",
              activeModuleCollection: "build/activeModuleCollection",
          })
        },
        methods: {
            setTitlePlaceholder() {
                this.placeholder.title = 'Type here';
            },
            openModal() {
                this.$modal.show('property-save-modal');
            },
            closeModal() {
                this.placeholder = {
                    title: 'Type here',
                };
                this.propObj = {
                    title: '',
                };
                this.$modal.hide('property-save-modal');
                this.$emit('toggleModalMode', false);
            },
            saveProperty() {
                const self = this;
                let mcId = self.$route.params.mcId
                self.$store.state.loading = true;
                self.propObj.module_id = self.$route.params.id;
                self.$store.dispatch("manageProperty/saveProperty", self.propObj)
                .then(response => {
                    if(response) {
                        self.$store.dispatch("build/getProperties", self.activeModuleCollection.id);
                        self.closeModal();
                    } else {
                        alert('Something went wrong.');
                    }
                    self.$store.state.loading = false;
                });
            }
        },
    }
</script>

<style scoped>

</style>
