<template>
  <div class="w-full">
    <div class="relative flex">
      <div class="i-left-panel shadow-one fs-14 fw-600 text-primary-three relative">
        <div class="relative">
          <img src="/images/instrat_logo.svg" alt="logo" class="i-insider-logo">
          <NavToggle v-if="projectId" />
        </div>

        <img src="/images/icons/properties/navigation.svg" alt="logo" class="mb-8 mt-10">

        <div v-if="projectModule && moduleData && moduleSettings" class="element-container">
          <transition-group name="list"
                v-for="(l1, l1Index) in moduleData"
                :key="l1Index">
            <div :key="'identifier_' + l1Index">
              <div class="relative flex justify-between items-center shadow-two rounded lvl-contents i-p-15 mb-8 cursor-pointer" :style="{'background': getBgStyle(l1)}"
                  :class="{'i-border-1 border-primary-one': parseInt(activeModuleCollection.id) === parseInt(l1.id)}">
                <div @click="toggleSelection(l1)" class="element-title-div flex items-center">
                  <span v-if="moduleSettings" class="ellipse prp-lft-title">
                      {{ getIndexTitle(true, 1, l1Index, l1.title) }}
                  </span>
                </div>
                <div @click="toggleExpand(l1)" v-if="l1.child.length > 0">
                  <img src="/images/icons/chevron_down_black.svg" alt="" :class="{'rotate-180': l1.expanded}" />
                </div>
              </div>

              <transition-group name="list"
                    v-for="(l2, l2Index) in l1.child"
                    :key="l2Index">
                <div :key="'area_' + l2Index" v-show="l1.expanded">
                  <div class="relative flex justify-between items-center shadow-two rounded lvl-contents i-p-15 mb-8 cursor-pointer" :style="{'background': getBgStyle(l2)}"
                      :class="{'i-border-1 border-primary-one': parseInt(activeModuleCollection.id) === parseInt(l2.id)}">
                    <div @click="toggleSelection(l2)" class="element-title-div flex items-center">
                      <span v-if="moduleSettings" class="ellipse prp-lft-title">
                            {{ getIndexTitle(false, 2, l2Index, l2.title) }}
                      </span>
                    </div>
                    <div @click="toggleExpand(l2)" v-if="l2.child.length > 0">
                      <img src="/images/icons/chevron_down_black.svg" alt="" :class="{'rotate-180': l2.expanded}" />
                    </div>
                  </div>

                  <transition-group name="list"
                      v-for="(l3, l3Index) in l2.child"
                      :key="l3Index">
                    <div :key="'area_measure' + l3Index" v-show="l2.expanded">
                      <div class="relative flex justify-between items-center shadow-two rounded lvl-contents i-p-15 mb-8 cursor-pointer" :style="{'background': getBgStyle(l3)}"
                          :class="{'i-border-1 border-primary-one': parseInt(activeModuleCollection.id) === parseInt(l3.id)}">
                        <div @click="toggleSelection(l3)" class="element-title-div flex items-center">
                          <span v-if="moduleSettings" class="ellipse prp-lft-title">
                                {{ getIndexTitle(false, 3, l3Index, l3.title) }}
                          </span>
                        </div>
                        <div @click="toggleExpand(l3)" v-if="l3.child.length > 0">
                          <img src="/images/icons/chevron_down_black.svg" alt="" :class="{'rotate-180': l3.expanded}" />
                        </div>
                      </div>

                      <transition-group name="list"
                          v-for="(l4, l4Index) in l3.child"
                          :key="l4Index">
                        <div :key="'measure' + l4Index" v-show="l3.expanded">
                          <div class="relative flex justify-between items-center shadow-two rounded lvl-contents i-p-15 mb-8 cursor-pointer" :style="{'background': getBgStyle(l4)}"
                               :class="{'i-border-1 border-primary-one': parseInt(activeModuleCollection.id) === parseInt(l4.id)}">
                            <div @click="toggleSelection(l4)" class="element-title-div flex items-center">
                              <span v-if="moduleSettings" class="ellipse prp-lft-title">
                                  {{ getIndexTitle(false, 4, l4Index, l4.title) }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </transition-group>

                    </div>
                  </transition-group>

                </div>
              </transition-group>

            </div>
          </transition-group>
        </div>
        <div class="lvl-contents p-5 flex justify-center items-center bg-primary-one shadow-two rounded cursor-pointer absolute build-back-button" @click="$router.go(-1)">
          <span>Go Back</span>
        </div>
      </div>
      <div class="i-right-panel full-height">
        <div class="ws-heading-box flex flex-col relative">
          <workspace-menu/>
          <div class="properties-area-selection flex justify-between">
              <div class="cursor-pointer" v-for="(item, index) in propAreaType" :key="index" @click="selectPropAreaType(index)">
                <h2 class="fs-14 fw-600 text-secondary-one" :class="item.selected ? 'opacity-100' : 'opacity-50'">{{ item.title }}</h2>
                <div class="flex justify-center mt-1" v-show="item.selected">
                    <span class="select-indicator"></span>
                </div>
              </div>
          </div>
        </div>
        <div class="property-loaded-dashborad text-secondary-one" style="color: #2BAD7E">
          <component :is="propertiesComponent" :item="itemSelected" :selected="activeModuleCollection" :properties="properties" class="i-pr-30"></component>
        </div>
      </div>
    </div>
    <Nav v-model="showNavigation" @close="showNavigation=false"/>
  </div>
</template>

<script setup>
import NavToggle from '@/components/navigation/components/NavToggle.vue'
import useNavigation from '@/components/navigation/useNavigation.js'
import Nav from '@/components/navigation/Nav.vue'

const { projectId, showNavigation } = useNavigation()
</script>

<script>
import Properties from "@/components/properties/Properties";
import Association from "@/components/properties/Association";
import Insights from "@/components/properties/Insights";
import Manage from "@/components/properties/Manage";
import { mapGetters } from 'vuex';



export default {
    async created() {
      const self = this;
      self.$Progress.start();
      let moduleId = self.$route.params.id;
      let mcId = self.$route.params.mcId;
      let projectId = self.$route.params.prId
      this.$store.dispatch("project/getSolidObject", projectId);
      this.$store.dispatch("definition/getDefinitionSets", { project_id: projectId });

      if (Object.keys(self.activeModuleCollection).length == 0) {

        this.$store.dispatch("workspace/projectSelection", projectId);
        this.$store.dispatch("build/loadWorkspaceModules", projectId);
        await this.$store.dispatch("build/getModules", projectId).then(response => {
          if (response && response.status) {
            self.$store.dispatch("build/fetchIndexData", {module: moduleId, initial: true}).then(response => {
              if (response && response.status) {
                self.findActiveDataById(response.data.data, mcId)
              }
            });
            self.$store.dispatch("build/loadCurrentData");
          }
        });
      }
      self.$store.dispatch("manageProperty/loadModuleCollections", moduleId);
      self.$store.dispatch("build/getProperties", mcId);
      self.$store.dispatch("insight/fetchIndexData", mcId);

      self.$Progress.finish();
    },
    data() {
        return {
            propertiesComponent: Properties,
            propAreaType: [
                {title: 'Properties', selected: true, component: Properties},
                {title: 'Association', selected: false, component: Association},
                {title: 'Insights', selected: false, component: Insights},
                {title: 'Manage', selected: false, component: Manage}
            ],
            itemSelected: '',
        };
    },
    computed: {
        ...mapGetters({
            projectModule: "manageProperty/projectModule",
            modulesData: "manageProperty/moduleData",
            buildData: "build/data",
            properties: "build/collectionProps",
            activeModuleCollection: "build/activeModuleCollection",
            moduleSettings: "build/currentSettings",
            collection_settings: 'brainstormv1/collection_settings',
            colorCodes: "global/colorCodes"
        }),
        moduleData() {
          let data = this.modulesData
          this.updateExpandStatus(data)
          return data
        },
        styleSettings() {
          let settings = this.moduleSettings && this.moduleSettings.collections && this.moduleSettings.collections.length > 0 ? this.moduleSettings : this.collection_settings;
          return settings;
        }
    },
    methods: {
        getIndexTitle(root, parentCodeIndex, index, title) {
            let nextNumber = index + 1;
            let returnTitle = '';
            if(this.styleSettings && this.styleSettings.collections) {
                if(this.styleSettings.index_id) {
                    returnTitle = this.styleSettings.collections[parentCodeIndex].index_id;
                }
                if(this.styleSettings.index_number) {
                    if(root) {
                        let number = parseInt(this.styleSettings.collections[parentCodeIndex].index_number) + parseInt(index);
                        returnTitle = returnTitle + number;
                    } else {
                        returnTitle = returnTitle + this.styleSettings.collections[parentCodeIndex - 1].index_number + '.' + nextNumber;
                    }
                }
                return returnTitle + ' ' + title;
            } else {
                return title;
            }
        },
        getBgStyle(el) {
            if (el.background_color) {
              return el.background_color
            }
            let settings = this.styleSettings.collections[el.level];
            if (settings) {
              if(settings.custom_bg) {
                return settings.custom_bg;
              }
              let levelColorCodes = this.getLevelWiseColorCode(el.level)
              return levelColorCodes[settings.color];
            }
            return "";
        },
        getLevelWiseColorCode(level) {
          let indexLevel = level - 1;
          return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
        },
        selectPropAreaType(index) {
            this.propAreaType.forEach((element, key) => {
                key === index ? element.selected = true : element.selected = false
            });
            this.propertiesComponent = this.propAreaType[index].component;
            if(index === 2) {
                this.itemSelected = '';
            }
        },
        toggleExpand(itemObject) {
            itemObject.expanded = !itemObject.expanded;
        },
        toggleSelection(itemObject) {
            this.itemSelected = itemObject;
            this.$store.dispatch("build/getProperties", itemObject.id);
            this.$store.dispatch("build/getActiveModuleCollection", itemObject);
            this.$store.dispatch("insight/fetchIndexData", itemObject.id);

        },
        findActiveDataById(tree, mcid) {
          tree.forEach(el => {
            if (el.id == mcid) {
              this.$store.dispatch('build/getActiveModuleCollection', el)
            }
            else {
              this.findActiveDataById(el.child, mcid)
            }
          });
        },
        updateExpandStatus(data) {
          data.forEach(el => {
            el.expanded = true
            if (el.child.length > 0) {
              this.updateExpandStatus(el.child)
            }
          });
        },
    },
    mounted() {

    },
    components: {

    },
    watch: {

    }
};
</script>

<style scoped>
.lvl-contents {
  width: 275px;
  height: 50px;
}
.properties-area-selection {
  width: 440px;
}
.build-back-button {
  bottom: 50px;
}
.element-container {
  height: 790px;
  overflow-y: scroll;
}
.element-title-div {
  width: 220px;
  height: 50px;
  overflow: hidden;
}
.property-loaded-dashborad {
  padding: 0 35px 0 40px;
  height: 865px;
}
</style>
