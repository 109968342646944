<template>
  <div>
    <div class="flex justify-start fs-14">
      <div class="assoc-details bg-primary-three rounded shadow-one mr-10 i-p-30 i-pt-15">
        <div class="flex justify-between mb-8 i-pb-15 i-border-b-1 border-secondary-two">
          <span class="fw-600 text-secondary-one">Details</span>
        </div>
        <div class="mb-4 shadow-two">
          <img class="rounded block w-full _my_img_size" :src="(selected.file) ? selected.file.file_path : '/images/build/demo_image_1.jpg'" alt="image">
        </div>
        <div>
          <div class="body-title mb-3 text-primary-one fw-600 break-words">
            <span class="">
              {{ getAssociatedModuleIndexId(selected.module_id, selected.level) }}
              {{ selected ? selected.title : '' }}
            </span>
          </div>
          <p class="short-desc mb-5 break-words text-secondary-one">
            {{
                selected && selected.description && selected.description.length > 250 ? selected.description.substr(0, 250) + '...' : selected.description
              }}
          </p>
        </div>
        <div>
          <h2 class="fw-600 text-primary-one i-pb-10 i-border-b-1 border-secondary-two mb-6">Associated content</h2>

          <div class="text-secondary-one fw-600 assoc-list-container overflow-y-scroll">
            <div class="flex justify-between i-mb-30 assoc-list-item opacity-50"
                 v-for="(list, index) in associatedData" :key="index">
              <h2 class="">
                {{
                  list.associated ? getAssociatedModuleIndexId(list.associated.module_id, list.associated.level) + ' ' + list.associated.title : 'No title Available'
                }}</h2>
              <div class="cursor-pointer remove-assoc"
                   v-if="canDelete(project.cans)"
                   @click="removeFromAssociation(list.id)">
                <img src="/images/icons/properties/off_outline_close.svg" alt="image">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="assoc-lists p-0 bg-transparent relative">
        <div class="i-pr-30 fw-600 text-primary-one">
            <div class="flex items-center justify-between cursor-pointer bg-primary-three shadow-one rounded i-h-50 i-pl-30 i-pr-30 mb-8">
              <input class="bg-transparent text-secondary-one" placeholder="Search" v-model="searchText" />
              <div class="flex">
                <img src="/images/icons/properties/search.svg" alt="image" class="cursor-pointer">

                <!-- <p class="pr-select-dropdown bg-transparent ml-5 pl-5 i-border-l-1 border-secondary-four">
                  {{ projectModules.find(el => el.id != choosedModule) ? projectModules.find(el => el.id == choosedModule).title : '' }}
                </p> -->
                <!-- <select class="pr-select-dropdown bg-transparent ml-5 pl-5 i-border-l-1 border-secondary-four" v-model="choosedModule">
                  <option v-for="(module, index) in projectModules" :key="index" :value="module.id" v-if="choosedModule != module.id">
                    {{ module.title }}
                  </option>
                </select> -->

                <select v-if="canEdit(project.cans)" class="pr-select-dropdown bg-transparent ml-5 pl-5 i-border-l-1 border-secondary-four" v-model="choosedModule">
                  <option value="null">Select Module</option>
                  <option v-for="(module, index) in projectModules" :key="index" v-if="$route.params.id != module.id" :value="module.id">
                    {{ module.title }}
                  </option>
                </select>

                <select v-if="canEdit(project.cans)"  class="pr-select-dropdown bg-transparent ml-10" v-model="choosedLevel">
                  <option value="1">Level 1</option>
                  <option value="2">Level 2</option>
                  <option value="3">Level 3</option>
                  <option value="4">Level 4</option>
                </select>
              </div>
            </div>
        </div>
          <div class="overflow-y-scroll ws-scrollbar i-pr-30" style="height: 783px; width: 975px;">
            <div class="grid grid-cols-3 gap-10">

              <div class="relative lib-item-container shadow-one cursor-pointer rounded overflow-hidden" v-for="(dData, index) in filteredData" :key="index"
                   :class="{'mt-8': index > 2,
                   'i-border-1 border-solid border-primary-one': checkAssociation(dData.id)}"
                   :index="index">
                <img :src="getImageSrc(dData)" alt="image" class="">
                <div class="absolute bottom-0 left-0 lib-item-info w-full i-p-15">
                  <h2 class="fw-600 text-secondary-one i-mb-5">{{ getModuleIndexId() }} {{ dData.title }} {{ dData.id }}</h2>
                  <p :title="dData.description" v-if="dData.description" class="text-primary-one">
                    {{
                      dData.description && dData.description.length > 117 ? dData.description.substr(0, 117) + '...' : dData.description
                    }}
                  </p>
                  <p :title="dData.description" v-else class="text-primary-one">
                    No description available.
                  </p>
                </div>
                <div
                    class="absolute flex justify-center items-center px-2 py-1 text-primary-three rounded-sm association-tag-box"
                    :style="{'background': getBgColor(dData)}">
                  <h2 class="fs-12">{{ dData.tag ? dData.tag : 'Tag' }}</h2>
                </div>
                <img src="/images/icons/properties/assoc.svg" alt="image" class="cursor-pointer absolute association-add-icon" v-if="!checkAssociation(dData.id) && canEdit(project.cans)" @click="toggleAssociation(dData.id)">
                <img src="/images/icons/properties/off_outline_close_box.svg" alt="image" class="cursor-pointer absolute association-add-icon" v-else-if="canDelete(project.cans) && checkAssociation(dData.id)" @click="toggleAssociation(dData.id)">
                <!-- <div class="absolute association-action-box cursor-pointer">
                    <img src="/images/icons/properties/minus_white.svg" alt="image"
                        class="association-action-icon" v-if="checkAssociation(dData.id)"
                        @click="removeAssociation(dData.id)">
                    <img src="/images/icons/properties/plus_white.svg" alt="image"
                        class="association-action-icon" v-else @click="addToAssociation(dData.id)">
                </div> -->
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script setup>
import usePermission from '@/composable/usePermission.js'
const { canDelete, canCreate, canEdit } = usePermission()
</script>
<script>
import {mapGetters} from 'vuex';

export default {
  name: "Association",
  props: ['selected'],
  data() {
    return {
      images: [
        '1.jpg',
        '2.jpg',
        '3.jpg',
        '4.jpg',
        '5.jpg',
        '6.jpg',
      ],
      choosedLevel: 1,
      choosedModule: 0,
      searchText: ''
    }
  },
  created() {
    const self = this;
    let projectId = this.$route.params.prId;
    if (projectId) {
      self.$store.dispatch("build/getModules", projectId).then(response => {
        if (response) {
          if (self.projectModules && self.projectModules.length > 0) {
            let itemIndex = self.projectModules.findIndex(mdl => parseInt(mdl.id) === parseInt(self.$route.params.id));
            if (itemIndex >= 0) {
              self.choosedModule = self.projectModules[itemIndex + 1].id;
            }
          }
          let maxModule = self.projectModules.length;
          if(self.projectModule.order === maxModule) {
              self.choosedModule = self.projectModules[0].id;
          } else {
              self.choosedModule = parseInt(self.selected.module_id) + 1;
          }
        }
      });

      self.$store.dispatch("association/getAllProjectModuleCollectionData", projectId);
    }

    this.getAssociatedData();
  },
  computed: {
    ...mapGetters({
      projectModule: "manageProperty/projectModule",
      projectModules: "build/projectModules",
      projectFullData: "association/projectFullData",
      associatedData: "association/associatedData",
      moduleSettings: "build/currentSettings",
      colorCodes: "global/colorCodes",
      project: "project/project"
    }),
    filteredData() {
      return this.getFilteredModuleData();
    }
  },
  methods: {
    getModuleIndexId() {
      let item = this.projectModules.find(item => parseInt(item.id) === parseInt(this.choosedModule));
      if (item) {
        let collectionsSettings = JSON.parse(item.collection_settings);
        if (collectionsSettings.index_id) {
          let id = collectionsSettings.collections[this.choosedLevel - 1].index_id;
          return id ? id : null;
        }
        return '';
      }
      return '';
    },
    getAssociatedModuleIndexId(moduleId, level) {
      let item = this.projectModules.find(item => parseInt(item.id) === parseInt(moduleId));
      if (item) {
        let collectionsSettings = JSON.parse(item.collection_settings);
        if (collectionsSettings.index_id) {
          let id = collectionsSettings.collections[level - 1].index_id;
          return id ? id : null;
        }
        return '';
      }
      return '';
    },
    getFilteredModuleData() {
      let level = this.choosedLevel;
      let moduleId = this.choosedModule;
      let data = this.projectFullData.filter(item => parseInt(item.level) === parseInt(level) && parseInt(item.module_id) === parseInt(moduleId) && !item.deleted_at);
      if (this.searchText) {
        return data.filter((item) => {
          return this.searchText
              .toLowerCase()
              .split(" ")
              .every((v) => item.title.toLowerCase().includes(v));
        });
      }
      return data;
    },
    getLevelWiseColorCode(level) {
      let indexLevel = level - 1;
      return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
    },
    getBgColor(item) {
      let colors = this.getLevelWiseColorCode(item.level);
      if(colors && colors.length > 0) {
        if (item.background_color) {
          return item.background_color;
        }
        let module = this.projectModules.find(mdl => parseInt(mdl.id) === parseInt(this.choosedModule));
        if(module) {
          let moduleSettings = JSON.parse(module.collection_settings);
          if(moduleSettings) {
            let settings = moduleSettings.collections[item.level];
            if (settings) {
              return colors[settings.color];
            }
          }
        }
        return null;
      }
      return null;
    },
    getRandomImages() {
      let index = Math.floor(Math.random() * 5);
      return this.images[index];
    },
    checkAssociationBg(id) {
      if (this.associatedData.find(item => item.associated_id === id)) {
        return "#25363F";
      }
      return "#28282A";
    },
    checkAssociation(id) {
      if (this.associatedData.find(item => item.associated_id === id)) {
        return true;
      }
      return false;
    },
    removeFromAssociation(id) {
      const self = this;
      self.$store.dispatch("association/removeAssociation", id);
    },
    toggleAssociation(id) {
      let item = this.associatedData.find(item => item.associated_id === id);
      if(item) {
        this.$store.dispatch("association/removeAssociation", item.id);
      } else {
        this.addToAssociation(id);
      }
    },
    removeAssociation(id) {
      const self = this;
      let item = self.associatedData.find(item => item.associated_id === id);
      if (item) {
        self.$store.dispatch("association/removeAssociation", item.id);
      }
    },
    addToAssociation(id) {
      const self = this;
      let data = {
        module_collection_id: self.selected.id,
        associated_id: id
      };
      self.$store.dispatch("association/storeAssociation", data);
    },
    getImageSrc(item) {
      if(item && item.file && item.file.file_path) {
        return item.file.file_path;
      }
      return require('../../assets/images/build/1.jpg');
      // if (fileName) {
      //   return require('../../assets/images/build/' + fileName);
      // }
      // return '';
    },
    getImage(src) {
      if (!src) {
        src = "inst_content.png";
      }
      return require('../../assets/images/build/' + src);
    },
    getAssociatedData() {
      if (this.selected) {
        this.$store.dispatch("association/getAssociatedData", this.selected.id);
      }
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
.assoc-details, .assoc-lists {
  height: 865px;
}

.assoc-list-container {
  width: 410px;
}

.assoc-list-item:hover {
  opacity: 1;
}

.remove-assoc {
  visibility: hidden;
}

.assoc-list-item:hover .remove-assoc {
  visibility: visible;
}

.lib-item-info {
  background: rgba(249, 249, 249, .9);
  height: 108px;
}

.lib-item-container {
  width: 290px;
}

.association-tag-box {
  top: 15px;
  right: 15px;
}
.association-add-icon {
  top: 15px;
  left: 15px;
}
.body-title, .short-desc {
  max-width: 410px;
}
</style>
