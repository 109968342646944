<template>
  <div>
    <div class="flex justify-start fs-14">
      <div class="propset-list bg-primary-three rounded shadow-one mr-10 i-p-30 i-pt-15">
        <div class="flex justify-between mb-8 i-pb-15 i-border-b-1 border-secondary-two">
          <h2 class="fw-600 text-secondary-one">Manage</h2>
          <div v-if="canCreate(project.cans) || canEdit(project.cans)" class="fw-600 text-primary-one flex justify-between">
            <h2 class="cursor-pointer mr-4" @click="modals.newProperty = true">+ Add New</h2>
            <h2 class="cursor-pointer" @click="modals.loadProperty = true">Load Set</h2>
          </div>
        </div>
        <div class="propset-list-container overflow-scroll">
          <div v-for="(rootProp, index) in properties" :key="index"
              class="rounded i-p-15 mb-8"
              :class="rootProp.is_visible ? 'bg-primary-four text-primary-two shadow-two' : 'bg-transparent i-border-1 border-secondary-one text-secondary-one'">
            <div class="flex justify-between items-center propset-row">
              <div class="flex h-5">
                <img :class="{'pointer-events-none': !canCreate(project.cans) && !canEdit(project.cans)}" src="/images/icons/properties/rectangle_active.svg" alt="image" class="cursor-pointer" @click="toggleRootChecked(rootProp.id)" v-if="rootProp.is_visible">
                <img :class="{'pointer-events-none': !canCreate(project.cans) && !canEdit(project.cans)}" src="/images/icons/properties/rectangle_inactive.svg" alt="image" class="cursor-pointer" @click="toggleRootChecked(rootProp.id)" v-else>
                <h2 @click="toggleRootSelection(rootProp)" class="cursor-pointer i-ml-10" :class="{'active-text': rootProp.id === rootItem.id}">{{ rootProp.title }}</h2>
              </div>
              <div class="cursor-pointer delete-icon" v-if="canDelete(project.cans)" @click="deleteProperty(rootProp.id)"><img src="/images/icons/properties/trash.svg" alt="image" class="cursor-pointer"></div>
            </div>
            <div class="i-mt-30 pl-6" v-show="rootItem.id === rootProp.id && rootProp.is_visible && rootProp.property_collections.length > 0">
              <div class="flex items-center justify-between propset-row" v-for="(collection, cIndex) in rootProp.property_collections" :key="cIndex" :class="cIndex != rootProp.property_collections.length - 1 ? 'mb-6': 'mb-4'">
                <div class="flex">
                  <img :class="{'pointer-events-none': !canCreate(project.cans) && !canEdit(project.cans)}" src="/images/icons/properties/rectangle_active.svg" alt="image" class="cursor-pointer" @click="toggleChildChecked(rootProp.id, collection.id)" v-if="collection.is_visible">
                  <img :class="{'pointer-events-none': !canCreate(project.cans) && !canEdit(project.cans)}" src="/images/icons/properties/rectangle_child_inactive.svg" alt="image" class="cursor-pointer" @click="toggleChildChecked(rootProp.id, collection.id)" v-else>
                  <h2 @click="toggleChildSelection(collection)" class="i-ml-10 cursor-pointer" :class="{'active-text': selectedChild.id === collection.id}">
                    {{ collection.prefix ? collection.prefix + ':' : '' }} {{ collection.title }} {{ collection.suffix ? '(' + collection.suffix + ')' : '' }}
                  </h2>
                </div>
                <div class="cursor-pointer delete-icon" v-if="canDelete(project.cans)" @click="deletePropertyCollection(collection.id)"><img src="/images/icons/properties/trash.svg" alt="image" class="cursor-pointer"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="manage-prop-lists">
        <manage-set ref="manage_set" :rootItem="rootItem" :selectedChild="selectedChild" :selected="selected" v-if="rootItem" @loadProperties="loadProperties" @toggleChildSelection="toggleChildSelection" />
      </div>
    </div>
    <new-property :dModal="modals.newProperty" @toggleModalMode='toggleNewModalMode' />
    <load-property :dModal="modals.loadProperty" @toggleModalMode="toggleLoadModalMode" />
  </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js'
  const { canDelete, canCreate, canEdit } = usePermission()
</script>

<script>
import ManageSet from "@/components/properties/ManageSet";
import NewProperty from "@/components/properties/NewProperty";
import LoadProperty from "@/components/properties/LoadProperty";
import {mapGetters} from 'vuex';
export default {
  name: "Manage",
  props: ["selected"],
  components: {
    ManageSet,
    NewProperty,
    LoadProperty
  },
  created() {
    const self = this;
    self.loadProperties();
    // self.loadDefinitions();
  },
  data() {
    return {
      rootItem: {},
      selectedChild: {},
      modals: {
        newProperty: false,
        saveProperty: false,
        loadProperty: false
      }
    }
  },
  computed: {
    ...mapGetters({
      properties: "manageProperty/properties",
      activeModuleCollection: "build/activeModuleCollection",
      project: "project/project"
    })
  },
  methods: {
    loadProperties() {
      const self = this;
      self.$store.dispatch("manageProperty/loadProperties", self.$route.params.id)
      .then(response => {
        if(response && self.rootItem) {
          let item = self.properties.find(item => item.id === self.rootItem.id);
          if(item) {
            self.rootItem = item;
          }
        }
      });
    },
    loadDefinitions() {
      this.$store.dispatch("definition/getDefinitionSets", { project_id: this.$route.params.id });
    },
    toggleRootSelection(item) {
      if (item.is_visible) {
        this.rootItem = item;
        this.selectedChild = {};
      }
      else {
        alert('Please, activate this set first.')
      }
    },
    toggleChildSelection(child) {
      this.selectedChild = child;
      this.$refs.manage_set.toggleEditMode(child.id);
    },
    toggleRootChecked(id) {
      const self = this;
      let mcId = self.$route.params.mcId
      self.properties.forEach(item => {
        if(item.id === id) {
          item.is_visible = !item.is_visible;
          let data = {
            id: id,
            is_visible: item.is_visible,
            type: 'property'
          };
          self.$store.dispatch("manageProperty/updatePropertyVisibility", data).then( response => {
            if (response) {
              self.$store.dispatch("build/getProperties", self.activeModuleCollection.id);
            }
          });
        }
      });
    },
    toggleChildChecked(rootId, childId) {
      const self = this;
      let mcId = self.$route.params.mcId
      let item =  self.properties.find(item => item.id === rootId);
      if(item) {
        item.property_collections.forEach(childItem => {
          if(childItem.id === childId) {
            childItem.is_visible = !childItem.is_visible;
            let data = {
              id: childId,
              is_visible: childItem.is_visible,
              type: 'collection'
            };
            self.$store.dispatch("manageProperty/updatePropertyVisibility", data).then( response => {
              if (response) {
                self.$store.dispatch("build/getProperties", self.activeModuleCollection.id);
              }
            });
          }
        });
      }
    },
    toggleNewModalMode(value) {
      this.modals.newProperty = value;
    },
    toggleLoadModalMode(value) {
      this.modals.loadProperty = value;
    },
    deleteProperty(id) {
      const self = this;
      var confirm = window.confirm("Are you sure? Deleting property set will also delete related data!");
      if (!confirm) {
        return false
      }
      self.$store.dispatch("manageProperty/deleteProperty", {id: id, module_id: self.activeModuleCollection.module_id}).then(response => {
          self.loadProperties()
          self.$store.dispatch("build/getProperties", self.activeModuleCollection.id);

      });
    },
    deletePropertyCollection(id) {
      const self = this;
      var confirm = window.confirm("Are you sure? Deleting property parameter will also delete related values!");
      if (!confirm) {
        return false
      }
      self.$store.dispatch("manageProperty/deletePropertyCollection", {id: id, module_id: self.activeModuleCollection.module_id}).then(response => {
        self.loadProperties()
        self.$store.dispatch("build/getProperties", self.activeModuleCollection.id);

      });
    }
  },
}
</script>

<style scoped>
.propset-list {
  height: 865px;
  width: 470px;
}
.propset-list-container {
  height: 750px;
}
.propset-list-container > div {
  width: 410px;
}
.delete-icon {
  visibility: hidden;
}
.propset-row:hover .delete-icon {
  visibility: visible;
}
.propset-row img {
  width: 14px;
}
</style>
